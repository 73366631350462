import { GetServerSideProps } from "next";
import { ReactElement } from "react";
import MainBanner from "@components/topclinicaltrials/mainBanner";
import TopClinicalTrialsWrapper from "@components/layouts/topClinicalTrialsWrapper";
import { getPageProps } from "@services/initial-calls";
import DiscoverTheBenefits from "@components/topclinicaltrials/discoverTheBenefits";
import PersonalizedMatching from "@components/topclinicaltrials/personalizedMatching";
import ComprehensiveSupport from "@components/topclinicaltrials/comprehensiveSupport";
import AdvancedTechnology from "@components/topclinicaltrials/advancedTechnology";
import ExpertCareComprehensiveHealth from "@components/topclinicaltrials/expertCareComprehensiveHealth";
import MatchTrialsAndWhyTrialsContainer from "@components/topclinicaltrials/matchTrialsAndWhyTrialsContainer";
import GetStartedToday from "@components/topclinicaltrials/getStartedToday";
export default function TopClinicalTrialsHome(): ReactElement {
    return (
        <TopClinicalTrialsWrapper whiteLogo>
            <MainBanner />
            <DiscoverTheBenefits />
            <PersonalizedMatching />
            <ComprehensiveSupport />
            <AdvancedTechnology />
            <ExpertCareComprehensiveHealth />
            <MatchTrialsAndWhyTrialsContainer />
            <GetStartedToday />
        </TopClinicalTrialsWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home", { fetchForm: true });
};
